/* .Cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */

.tracks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-left: 5em;
  margin-right: 5em; */
  margin: 2em;
  flex-wrap: wrap;
}

.card {
  /* flex: 2; */
  height: 300px;
  width: 280px;
  background-color: rgba(255, 255, 255, 0.06);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  position: relative;
  margin: 1em;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  border-radius: 8px;
  -webkit-box-shadow: 20px 20px 22px rgba(84, 84, 84, 0.033);
  box-shadow: 20px 20px 22px rgba(0, 0, 0, 0.114);
  font-family: "Poppins", sans-serif;
}

.card:hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.card:hover,
.card:focus,
.card:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.card-img {
  height: 120px;
  width: 120px;
  margin: 20px auto 20px auto;
  left: 0;
  right: 0;
  overflow: hidden;
}
.card-img img {
  height: 100%;
  border-radius: 50%;
  margin-left: 7%;
  padding-top: 6%;
  overflow: hidden;
}

.desc {
  width: 100%;
  text-align: center;
  /* position: absolute; */
  top: 160px;
}
.primary-text {
  color: #d5d5d5;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin: 5px 0;
}
.secondary-text {
  color: #e0e0e0;
  font-weight: 400;
  font-size: 16px;
  /* letter-spacing: 1px; */
  margin: 5px 0;
}
.card button {
  width: 50%;
  padding: 12px 0;
  font-size: 14px;
  color: #d5d5d5;
  position: absolute;
  left: 25%;
  top: 76%;
  border-radius: 5px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.06);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border: none;
}

.card button:hover {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.card button:hover,
.card button:focus,
.card button:active {
  background-color: #00a1ea;
  color: white;
}

/* @media only screen and (max-width: 600px) {
  .Cards {
    flex-direction: column;
  }
} */

@media (max-width: 600px) {
  .tracks {
    margin: 0;
    overflow-y: hidden;
  }

  .card {
    width: 260px;
  }
}
