.vision {
  margin-bottom: 8rem;
}

.vision-text {
  color: white;
  margin: 3em 10em;
  margin-right: 50%;
  line-height: 1.5em;
  font-size: 16px;
}

.vision-vector {
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(0.7);
}

@media (max-width: 900px) {
  .vision {
    margin: 3rem 0;
  }

  .vision-text {
    color: white;
    margin: 0 3rem;
    line-height: 1.5em;
    font-size: 16px;
  }

  .vision-vector {
    display: none;
  }
  .mobile-para {
    display: none;
  }
}
