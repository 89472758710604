.event-header {
  position: relative;
  /* z-index: 4; */
  width: 100vw;
  /* height: 100vh; */
}
.event-landing {
  padding: 36px;
  /* margin-top: 4rem; */
  position: relative;
  background-image: url("/src/assets/bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.event-row-container {
  min-height: 100vh;
  display: flex;
  padding: 50px 0;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}
.banner {
  /* position: relative; */
  height: 500px;
  padding: 10px;
  flex-basis: 38%;
  background-size: cover;
  /* overflow: hidden; */
  /* vertical-align: middle; */
}
.event-row-container .content-column {
  /* max-height: 50vh; */
  overflow-y: hidden;
}
.event-row-container .content-column .inner-column {
  /* right: 50px; */
  display: flex;
  flex-direction: column;
  margin-top: 6.2rem;
  margin-left: 5rem;
  text-align: left;
  overflow-y: hidden;
}
.event-row-container .content-column .inner-column h2 {
  /* position: relative; */
  display: block;
  padding-bottom: 1rem;
  font-family: Sora;
  font-size: 55px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  overflow: hidden;
}

.event-row-container .content-column .inner-column .text {
  /* position: relative; */
  font-family: Sora;
  font-size: 30px;
  color: #ffffff;
  font-weight: 500;
  line-height: 1.3em;
  margin-top: 6rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  text-transform: capitalize;
  overflow: hidden;
  border-left: solid 5px white;
}

.event-row-container .content-column .inner-column h3 {
  font-family: Sora;
  color: #ffffff;
  font-size: 25px;
  font-weight: 400;
  line-height: 2rem;
}

.landing-date,
.landing-location {
  display: flex;
  align-items: center;
}
.landing-location-vector,
.landing-date-vector {
  width: 40px;
  text-align: center;
}

.event-row-container .content-column .inner-column .text img {
  transform: scale(0.8);
}

.landing-date-text {
  padding-left: 20px;
  padding-bottom: 5px;
}
.landing-date-mob {
  display: none;
}
.br-tagline {
  display: none;
}
.loc {
  display: none;
}

/* .event-landing::before{
    content: '';
    float: left;
    height: 100%;
}
.event-countdown-container::after{
    content: '';
    display: block;
    clear: both;
    height: 100%;
} */
.event-countdown-container {
  border-left: 5px solid #00a1ea;
  /* position: relative; */
  /* margin-top:100px; */
  overflow-x: visible;
}

.event-reg-btn-header {
  margin-top: 28px;
  overflow-x: visible;
}
.header-text {
  width: auto;
  color: white;
  font-size: 23px;
  font-weight: 400;
  padding-left: 135px;
  padding-right: 50px;
}
.counter {
  /* margin-left: 20px;
  padding-top: 90px; */
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  margin-top: 20rem;
  overflow-y: hidden;
}

.reg-closed{
  display: flex;
  justify-content: start;
  font-family: var(--primary-font);
  font-size: 1.3rem;
  font-weight: 600;
  margin: 2rem 0;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: transparent;
  position: relative;
}

.reg_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  font-family: var(--primary-font);
  width: 246px;
  height: 60px;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: transparent;
  border: 2.5px solid #ffffff;
  border-radius: 10px;
  position: relative;
  transform-style: preserve-3d;
  margin: 1rem 0;
  cursor: pointer;
}

.reg_btn:hover {
  color: #0085ff;
  transition: all 0.3s ease-in-out;
  background-color: #ffff;
}

@media screen and (max-width: 1300px) {
  .event-row-container {
    text-align: center;
  }
  .header-text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .event-header-image {
    display: none;
  }
  .event-reg-btn-header {
    padding-left: 0px;
  }
  .event-countdown-container {
    display: none;
  }
  .event-landing {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* .counter {
    margin-left: auto;
  } */
}

@media (max-width: 900px) {
  .event-landing {
    background-image: url("/src/assets/bg3.png");
  }
  .event-row-container {
    padding-top: 8rem;
  }
  .event-row-container .content-column .inner-column .text {
    display: none;
  }
  .loc {
    display: flex;
    margin-top: 1.6rem;
    margin-bottom: 3rem;
  }
  .loc-text {
    font-size: 1rem;
    color: white;
    margin-left: 1rem;
  }
  .loc-vector img {
    height: 1.5rem;
    margin-top: 0.3rem;
  }

  .event-row-container .content-column .inner-column h2 {
    font-size: 2rem;
  }
  .event-row-container .content-column .inner-column {
    margin: 0;
  }
  .landing-subheading {
    display: flex;
    justify-content: space-between;
  }
  .br-tagline {
    display: block;
  }
  .landing-date-mob {
    display: flex;
    align-items: center;
    font-family: Sora;
    font-size: 15px;
    width: 6rem;
    padding-left: 1.5rem;
    color: #ffffff;
    background-color: #5f6886;
    border-radius: 0 10px 10px 0;
    border-left: solid 5px #0085ff;
    font-weight: 600;
  }
  .event-row-container .content-column .inner-column h3 {
    display: block;
    font-family: Sora;
    font-weight: 700;
  }
  .event-landing {
    background-size: cover;
    background-position: center;
  }

  .counter {
    margin-top: 5rem;
  }
}
@media only screen and (max-width: 480px) {
  .event-row-container .content-column .inner-column h2 {
    font-size: 37px;
  }
  .reg_btn {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .reg_btn {
    /* width: 150px; */
    font-size: 1rem;
  }
  .reg_btn::before {
    /* width: 150px; */
  }
}
@media only screen and (max-width: 600px) {
  .reg_btn {
    font-size: 1rem;
  }
}
