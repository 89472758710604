#community {
  position: relative;
  z-index: 4;
}
.community-container {
  color: white;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  max-width: 1200px;
  flex-wrap: wrap;
  /* margin-top: 2em;
    margin-left: 5em; */
  margin: 2em 7.2em;
  /* margin-bottom: 4em; */
}
.community {
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(21px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  margin-right: 5.4em;
  flex-direction: column;
}
.community-img {
  width: 180px;
  height: 180px;
  overflow: hidden;
}

@media (max-width: 1200px) {
}

@media (max-width: 600px) {
  .community-container {
    flex-direction: column;
    margin: 0;
    margin-top: 3em;
  }
  .community {
    margin: 0;
    margin-bottom: 2em;
    width: 175px;
    height: 175px;
  }
  .community-img {
    width: 140px;
    height: 140px;
    overflow: hidden;
  }
}
