.faq {
  --background-color: #cc0045;
  --color: white;
  --text-color: rgba(0, 40, 116, 1);

  width: 100vw;
  padding: 40px 20px;
  background-color: var(--background-color);
}

.faq-container {
  margin: auto;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background: var(--color);
  width: 75%;
  z-index: 3;
  position: relative;
}
.faq-container :focus {
  outline: none;
}

.question-wrapper {
  width: 100%;
  font-family: Poppins;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1.1rem;
  padding: 5px;
  display: flex;
}

.faq-answer {
  margin-top: 10px;
  font-family: Montserrat;
  line-height: 24px;
  padding-left: 30px;
  padding-bottom: 20px;
  margin-right: 20px;
  font-weight: 500;
  color: white;
}

.faq.event {
  --background-color: #19072c00;
  --color: #04364f;
  --text-color: rgba(255, 255, 255, 1);
}

.faq.angelFaq {
  --color: #195ac7;
}
a {
  text-decoration: none;
  color: white;
}

.coc {
  text-decoration: underline;
}

@media only screen and (max-width: 500px) {
  .faq-container {
    width: 100%;
  }

  .faq-title {
    text-align: center;
  }
  .question-wrapper {
    font-size: 16px;
  }
  .faq-answer {
    font-size: 14px;
  }
}
