/* .event-footer{
    width: 100vw;
    position: relative;
    height: 200px;
    background: #041c2b;
} */

.event-footer {
  position: relative;
  background-color: var(--eventFooterBg);
  color: white;
  line-height: 1.5rem;
  z-index: 4;
  overflow: hidden;
  margin: 0;
}

.event-footer .container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  text-align: center;
  line-height: 1.8em;
}

.event-footer .socials img {
  margin-left: 1rem;
  /* height: 2rem; */
}

.coc {
  text-decoration-thickness: 1px;
}

hr {
  border: none;
  width: 100%;
  height: 44px;
  /* margin-top: 50px; */
  border-bottom: 1px solid #1f1209;
  box-shadow: 0 20px 20px -20px #0171d4;
  /* margin: -50px auto 10px; */
  margin: 0px 10px;
}

.iedc-logo {
  width: 220px;
  height: 44px;
  /* margin-right: 2em; */
}

@media screen and (min-width: 1080px) {
  .event-footer-margin {
    position: absolute;
    top: 0;
    left: 0;
    height: 9rem;
    width: 105px;
    border-right: 5px solid #00a1ea;
    overflow-x: visible;
  }

  .event-footer-margin::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -10px;
    width: 15px;
    height: 15px;
    background-color: #00a1ea;
  }
  .left-border-top-square {
    position: absolute;
    height: 15px;
    width: 15px;
    top: 50px;
    left: -10px;
    background: #00a1ea;
  }

  .event-footer .container {
    padding: 6rem 11rem;
    align-items: center;
  }

  .event-footer .container > div {
    flex-grow: 1;
    padding: 0 1rem;
  }

  .event-footer .description-container {
    align-self: center;
    flex-grow: 2;
  }

  .event-footer .description {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .event-footer .socials {
    display: flex;
    justify-content: center;
  }

  .event-footer .socials a:first-child {
    margin-left: 0;
  }

  .event-footer .socials a {
    margin-top: 1rem;
    margin-left: 1.5rem;
  }

  .event-footer .form-container {
    margin-left: 2rem;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1080px) {
  .event-footer .container {
    padding: 3rem 2rem;
    flex-direction: column;
    width: 100%;
  }

  .event-footer .logo {
    margin-bottom: 1rem;
  }

  .event-footer .socials {
    display: flex;
    justify-content: center;
  }

  .event-footer .socials a {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    display: block;
  }

  .event-footer .form-container {
    margin-top: 1rem;
    align-self: center;
    white-space: normal;
  }
}
