.Countdown {
  /* margin: 10px auto; */
  padding-top: 20px;
  font-family: arial;
  color: #fff;
  width: content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Countdown-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 10rem;
}

.Countdown-col-element {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(180deg, #309cff 50%, #0085ff 50%);
  border-radius: 0px 25.9624px 0px 25.9624px;
  box-shadow: 0px 12px #000000;
  padding: 0.6rem 1rem;
  width: 110px;
}

.Countdown-col-element strong {
  font-size: 4rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22.2297px;
  text-transform: uppercase;
}

.Counter-sub {
  font-family: Montserrat;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.07em;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 1080px) {
  .Countdown-col-element strong {
    font-size: 3.8rem;
    text-align: left;
  }
  .Countdown-col-element span {
    margin-left: 6px;
  }

  .Countdown-col-element {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1080px) {
  .Countdown-col-element strong {
    font-size: 2rem;
    text-align: center;
  }

  .Countdown-col-element {
    margin: 0 4px;
    width: 90px;
    height: 80px;
  }

  .Counter-sub {
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
  }
}
