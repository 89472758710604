.tracks-modal {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 100%;
  padding: 3rem;
}
.tracks-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.tracks-modal-header-left {
  display: flex;
  justify-content: flex-start;
  row-gap: 1.5rem;
  flex-direction: column;
}
.tracks-modal-header-left-heading {
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
  text-transform: capitalize;
  color: #ffffff;
}
.tracks-modal-left-sub {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;
}
.tracks-modal-left-sub-heading {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-transform: lowercase;
  color: #ffffff;
}
.tracks-modal-left-sub-image {
  width: 200px;
  height: auto;
}
.tracks-modal-close {
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 700;
}
.tracks-modal-problem {
  background: rgba(0, 70, 105, 0.31);
  border-radius: 20px;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  row-gap: 2rem;
}
.tracks-modal-problem-heading {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  text-transform: capitalize;
}
.tracks-modal-powered-by {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  width: 180px;
  z-index: 2;
  transform: translateY(160%);
  background-color: white;
  transform-origin: top right;
  position: relative;
  top: 0;
  left: 0;
  padding: 0.5rem;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-transform: lowercase;
  color: #04364f;
}
.tracks-modal-problem-statements {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.tracks-modal-problem-statement {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5em;
}
.tracks-modal-prize-section {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  padding-left: 6rem;
  background: #052737;
  border-radius: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}
.tracks-modal-prize-card {
  width: 260px;
  height: 130px;
  background: #033b58;
  box-shadow: 4px 4px 13px rgba(6, 35, 49, 0.87);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}
.track-modal-prize-position {
  width: 92px;
  height: 80px;
}
.track-modal-prize-position-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.7);
}
.track-modal-prize-position-money {
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: rgba(255, 255, 255, 0.85);
}
.tracks-modal-img {
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}
@media (max-width: 1096px) {
  .tracks-modal-left-sub-image {
    width: 150px;
    margin-top: 1rem;
    height: auto;
  }
}
@media (max-width: 700px) {
  .tracks-modal {
    row-gap: 1rem;
    padding: 2rem;
    padding-top: 0rem !important;
  }
  .tracks-modal-powered-by {
    transform: translateY(120%);
  }
  .tracks-modal-header-left-heading {
    font-size: 28px;
    line-height: 38px;
  }
  .tracks-modal-left-sub-heading {
    font-size: 16px;
    line-height: 20px;
  }
  .tracks-modal-left-sub-image {
    width: 150px;
    height: auto;
  }
  .tracks-modal-prize-section {
    gap: 2rem;
    padding-left: 2rem;
  }
  .tracks-modal-problem-heading {
    font-size: 22px;
    text-align: left;
    line-height: 28px;
  }
  .tracks-modal-problem {
    padding: 1.3rem;
    text-align: left;
  }
  .tracks-modal-problem-statement {
    font-size: 16px;
    line-height: 25px;
    text-align: left;
  }
  .tracks-modal-prize-card {
    width: 240px;
    height: 100px;
  }
  .track-modal-prize-position {
    width: 72px;
    height: 60px;
  }
  .track-modal-prize-position-text {
    font-size: 14px;
    line-height: 20px;
  }
  .track-modal-prize-position-money {
    font-size: 18px;
    line-height: 27px;
  }
}
@media (max-width: 450px) {
  .tracks-modal-header-left-heading {
    font-size: 22px;
    line-height: 28px;
    text-align: left;
    margin-right: 10px;
  }
  .tracks-modal-prize-section {
    padding-top: 3rem;
  }
  .tracks-modal-close {
    font-size: 24px;
  }
  .tracks-modal-left-sub-heading {
    font-size: 14px;
    line-height: 18px;
  }
  .tracks-modal-left-sub-image {
    width: 100px;
    height: auto;
  }
  .tracks-modal-problem-heading {
    font-size: 18px;
    line-height: 22px;
  }
  .tracks-modal-problem-statement {
    font-size: 14px;
    line-height: 20px;
  }
  /* .tracks-modal-prize-card {
    width: 150px;
    height: 150px;
  } */
  .track-modal-prize-position {
    width: 52px;
    height: 40px;
  }
  .track-modal-prize-position-text {
    font-size: 12px;
    line-height: 18px;
  }
  .track-modal-prize-position-money {
    font-size: 16px;
    line-height: 24px;
  }
}
