.event-navbar{
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-direction: row;
    padding: 30px 20px;
    background-color: transparent;
    transition: 0.3s;
    z-index: 2;
}
.techno-logo{
    margin-left: 30px;
    height: 20%;
    width: 20%;
}

.nav-sections{
    display: flex;
    justify-content: space-around;
    width: 45rem;
}

.event-nav-link{
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    font-size: 19px;
    line-height: 34px;
    text-transform: uppercase;
    color: white;
    /* transition: 0.6s; */
}


.event-nav-link>a{
    text-decoration: none;
    color: #FFFFFF;
    /* transition: 0.6s; */
}

.event-active{
    font-weight: bold;
}

.techno-logo-mobile,.hamburger-menu,.nav-hamburger{
    display:none;
}

.nav-wrapper{
    text-align: center;
}

@media screen and (min-width: 901px) {
    .event-navbar.scroll{
        background-color: #0000;
        padding: 20px 20px;
        backdrop-filter: blur(10px);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    }
}

@media only screen and (max-width: 1200px) {
    .event-nav-link{
        font-size:17px;
    }
  }
  @media only screen and (max-width: 900px) {
    .event-navbar{
        position: absolute;
        z-index: unset;
    }

    .nav-hamburger{
        display: block;
      overflow: hidden;
      z-index: 5;
      margin-right: 5px;
    }
    .hamburger-menu{
        display: block;
        position: absolute;
        z-index: 5;
    }
    .bm-burger-button {
        display: none;
      }

    .bm-menu {
        background: #041C2B;
        opacity: 0.85;
        padding: 4em 1.5em 0;
        font-size: 1.15em;
      }

      .bm-item-list {
        /* margin-top: 0.5rem; */
        padding: 0.8em;
      }  

    .bm-item {
        color: #FFFFFF;
        margin-top: 30px;
      }

    .nav-sections,.techno-logo{
        display: none;
    }
    .event-nav-link{
        font-size:15px;
    }
    .techno-logo-mobile{
        position: relative;
        display: inline-block;
        width:50%;
        height:50%;
        z-index: 2;
    }
    
  }

  @media only screen and (max-width: 400px) {
   
    .event-nav-link{
        font-size:12px;
    }
  }