.event-page {
  background: #041c2b;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  overflow-x: hidden;
  overflow-y: hidden !important;
  height: fit-content;
}
.event-background-container {
  position: relative;
  z-index: 1;
  margin-top: 50px;
}
.event-main {
  /* margin-top: -200px; */
  margin-left: 100px;
  border-left: 5px solid #00a1ea;
  width: 100vw;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 4;
  overflow-x: visible;
}

.border-top-square {
  position: absolute;
  height: 15px;
  width: 15px;
  top: 0px;
  left: 95px;
  background: #00a1ea;
}

@media (min-height: 850px) {
  .event-main {
    margin-top: -350px;
  }
}

@media (min-width: 450px) {
  .event-main {
    margin-top: -50px;
  }
}

@media (max-width: 480px) {
  .event-main {
    margin-top: -100px;
  }
}

@media (max-width: 900px) {
  .event-main {
    margin-left: 0;
    border-left: hidden;
    padding-bottom: 0;
  }
  .border-top-square{
    display: none;
  }
}
